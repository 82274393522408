export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

export const setTokens = ({ accessToken = undefined }) => {
  if (accessToken) window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
};

export const clearTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
};

export const getAccessToken = () => {
  let accessToken = undefined;
  if (typeof window !== 'undefined') {
    accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  }
  return accessToken;
};
