import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { config } from 'libs/react-query';
import { AuthProvider } from 'contexts/auth-context';
import Notification from './ui-component/notification';
import Upload from 'ui-component/upload/index.js';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const [queryClient] = useState(() => new QueryClient(config));
  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <AuthProvider>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Upload />
              <Notification />
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  );
};

export default App;
