import { getAccessToken } from 'utils/storage';
import axios from 'axios';
import { dispatch } from 'store';
import { notificationActions } from 'store/reducers/notification.js';

const axiosClient = axios.create({
  timeout: 50000
});

axiosClient.interceptors.request.use(
  function (config) {
    config.data.company_code = 'TRUEBPM';
    config.data.outputtype = 'RawJson';
    const accessToken = getAccessToken();
    if (accessToken && !config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data || error.message;
    dispatch(
      notificationActions.open({
        severity: 'error',
        message
      })
    );

    return Promise.reject(error);
  }
);

export default axiosClient;
