// assets
import { IconApps, IconPackage, IconStack, IconUserDollar, IconUsers } from '@tabler/icons-react';

const pages = {
  id: 'pages',
  title: 'Pages',
  caption: 'Management pages',
  type: 'group',
  children: [
    {
      id: 'packages',
      title: 'Packages',
      type: 'item',
      url: '/packages',
      icon: IconPackage
    },
    {
      id: 'tabs',
      title: 'Tabs',
      type: 'item',
      url: '/tabs',
      icon: IconStack
    },
    {
      id: 'modules',
      title: 'Modules',
      type: 'item',
      url: '/modules',
      icon: IconApps
    },
    {
      id: 'functions',
      title: 'Functions',
      type: 'item',
      url: '/functions',
      icon: IconApps
    },
    {
      id: 'customer',
      title: 'Customer',
      type: 'item',
      url: '/customers',
      icon: IconUsers
    },
    {
      id: 'sales',
      title: 'Sales',
      type: 'item',
      url: '/sales',
      icon: IconUserDollar
    }
  ]
};

export default pages;
