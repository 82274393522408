import React from 'react';
import { useSelector } from 'react-redux';
import { Fade, Grow, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { dispatch } from 'store';
import { notificationActions } from 'store/reducers/notification.js';

function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const transition = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade: Fade
};

const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(notificationActions.close());
  };

  return (
    <React.Fragment>
      <MuiSnackbar
        anchorOrigin={notification.anchorOrigin}
        open={notification.open}
        autoHideDuration={notification.autoHideDuration}
        onClose={handleClose}
        TransitionComponent={transition[notification.transition]}
      >
        <Alert variant="filled" onClose={handleClose} severity={notification.severity}>
          {notification.message}
        </Alert>
      </MuiSnackbar>
    </React.Fragment>
  );
};

export default Notification;
