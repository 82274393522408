import { createSlice } from '@reduxjs/toolkit';
import { clearTokens } from 'utils/storage.js';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    init(state, action) {
      state.isAuthenticated = action.payload?.isAuthenticated;
      state.isLoading = false;
      state.user = action.payload?.user;
      return state;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = undefined;
      clearTokens();
      return state;
    }
  }
});

export default auth.reducer;

export const authActions = auth.actions;
