import { combineReducers } from 'redux';

import auth from './auth';
import customization from './customization';
import notification from './notification';
import upload from './upload';

const reducers = combineReducers({ auth, customization, notification, upload });

export default reducers;
