import { clearTokens, getAccessToken, setTokens } from 'utils/storage';
import { createContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLoginMutation } from 'hooks/mutations/auth';
import { useGetMe } from 'hooks/queries/user';
import { dispatch } from 'store/index';
import { authActions } from 'store/reducers/auth';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

export const AuthProvider = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const loginMutation = useLoginMutation();
  const ref = useRef(false);
  const getMeQuery = useGetMe({ enabled: !!isAuthenticated, refetchOnWindowFocus: false, staleTime: Infinity });

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    const loadAccessToken = async () => {
      const accessToken = getAccessToken();
      const isExpired = !verifyToken(accessToken);
      let user = null;
      if (!isExpired) {
        user = (await getMeQuery.refetch()).data;
      }
      dispatch(authActions.init({ user, isAuthenticated: !isExpired }));
    };
    loadAccessToken();
  }, []);

  const signIn = async (email_address, password) => {
    const { data, code, message } = await loginMutation.mutateAsync({ email_address, password });
    if (code !== 0) {
      throw new Error(message);
    }
    setTokens({ accessToken: data.token });
    const { data: user } = await getMeQuery.refetch({ throwOnError: true });
    dispatch(authActions.init({ user, isAuthenticated: true }));
  };

  const signOut = async () => {
    clearTokens();
    dispatch(authActions.logout());
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};
