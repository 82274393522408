import axiosClient from 'libs/axios';

const ENDPOINT = {
  login: '/Primary/?FlowAlias=bs_api_user_get_profile_current_user&action=api'
};

const me = async () => {
  const { data } = await axiosClient.post(ENDPOINT.login, {});
  return data.data;
};

export const userService = { me };
