import axiosClient from 'libs/axios';

const AUTH_ENDPOINT = {
  login: '/Primary/?FlowAlias=bs_api_user_authenticate&action=api'
};

const login = async ({ email_address, password }) => {
  const { data } = await axiosClient.post(AUTH_ENDPOINT.login, {
    email_address,
    password,
    guest: true
  });
  return data;
};

export const authService = { login };
