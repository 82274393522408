import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Module = Loadable(lazy(() => import('views/pages/module')));
const Package = Loadable(lazy(() => import('views/pages/package')));
const Customer = Loadable(lazy(() => import('views/pages/customer')));
const Sales = Loadable(lazy(() => import('views/pages/sales')));
const Tabs = Loadable(lazy(() => import('views/pages/tab')));
const Function = Loadable(lazy(() => import('views/pages/function')));
import { AuthGuard } from './../guards/auth-guard';

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'modules',
      element: <Module />
    },
    {
      path: 'packages',
      element: <Package />
    },
    {
      path: 'functions',
      element: <Function />
    },
    {
      path: 'customers',
      element: <Customer />
    },
    {
      path: 'sales',
      element: <Sales />
    },
    {
      path: 'tabs',
      element: <Tabs />
    }
  ]
};

export default MainRoutes;
