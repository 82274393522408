import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  folder: 'Upload',
  fileType: 'other',
  onSuccess: () => {}
};

const upload = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    open(state, action) {
      state.open = true;
      state.folder = action.payload.folder;
      state.fileType = action.payload.fileType;
      state.onSuccess = action.payload.onSuccess;
      return state;
    },
    close(state) {
      state.open = false;
      return state;
    }
  }
});

export default upload.reducer;

export const uploadActions = upload.actions;
