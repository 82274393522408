import config from 'config.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export const GuestGuard = (props) => {
  const { children } = props;
  const { isAuthenticated } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return children;
};
